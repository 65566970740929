import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../utils/AuthProvider';
import Header from '../components/header';

import { Outlet, useLocation } from 'react-router-dom';

const Workspace = () => {
    const { user, logout } = useAuth();
    const navigate = useNavigate();
    const [data_table, setData_table] = useState([]);
    const [formData, setFormData] = useState({
        apartments_title: '',
        images: []
    });

    useEffect(() => {
        const checkAdmin = async () => {
            if (user) {
                try {
                    const response = await fetch(`/api/check_admin/${user.username}`);
                    const isAdmin = await response.json();
                    if (!isAdmin) {
                        logout();
                        navigate('/login');
                    }
                } catch (error) {
                    console.error('Failed to check admin status', error);
                    logout();
                    navigate('/login');
                }
            }
        };
        checkAdmin();
    }, [user, logout, navigate]);

    const handleChange = (e) => {
        if (e.target.name === 'images') {
            setFormData({
                ...formData,
                images: Array.from(e.target.files)
            });
        } else {
            setFormData({
                ...formData,
                [e.target.name]: e.target.value
            });
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formdata = new FormData();
        formdata.append("apartments_title", formData.apartments_title);

        formData.images.forEach((image) => {
            formdata.append("images[]", image);
        });


        const requestOptions = {
            method: 'POST',
            body: formdata,
            redirect: 'follow'
        };

        try {
            const response = await fetch("https://reagent-tool.webtm.ru/api/apartments", requestOptions);
            const result = await response.text();
            console.log(result);
        } catch (error) {
            console.log('error', error);
        }
    };
    const location = (useLocation().pathname).replace('/', '');
    const [curPath, setCurPath] = useState(location)
    // console.log(curPath)

    return (
        <div className='mainWorkSpace'>
            
            <Header />
            <div className='nav-menu'>
                <button className={`nm-btn ${(curPath === 'projects') ? 'nm-btn-active' : ''}`} onClick={() => (navigate('/projects'), setCurPath('projects'))}>Проекты</button>
                <button className={`nm-btn ${(curPath === 'users') ? 'nm-btn-active' : ''}`} onClick={() => (navigate('/users'), setCurPath('users'))}>Пользователи</button>
                <button className={`nm-btn ${(curPath === 'lang') ? 'nm-btn-active' : ''}`} onClick={() => (navigate('/lang'), setCurPath('lang'))}>Настройки языка</button>
                <button className={`nm-btn ${(curPath === 'requests') ? 'nm-btn-active' : ''}`} onClick={() => (navigate('/requests'), setCurPath('requests'))}>Запросы</button>
            </div>
            <Outlet />



            {/* <form
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '300px'
                }}
                onSubmit={handleSubmit}>
                <input type="file" name="images" multiple onChange={handleChange} placeholder="Images" />
                <input type="text" name="apartments_title" value={formData.apartments_title} onChange={handleChange} placeholder="Apartment Title" />
                <button type="submit">Submit</button>
            </form> */}
        </div>
    );
};

export default Workspace;

