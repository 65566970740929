import React, { useState, useEffect } from 'react';

function EditMessages() {
    const [lang, setLang] = useState('EN');
    const [messages, setMessages] = useState({});
    const [notifi, setNotifi] = useState('')

    const getLang = async () => {
        try {
            const response = await fetch(`/api/get_language`);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const language = await response.json();
            setMessages(language);
        } catch (error) {
            console.log(error);
        }
    };
    useEffect(() => {

        getLang();
    }, []);

    const handleInputChange = (e, key) => {
        setMessages({
            ...messages,
            [lang]: {
                ...messages[lang],
                [key]: e.target.value,
            },
        });
    };

    const handleSave = async () => {
        const data = { data: messages[lang], lang: lang };
        try {
            const response = await fetch(`/api/rename_json`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            });
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            setNotifi('Язык успешно обновлен')
            getLang()
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <div style={{ marginTop: '20px' }} className='marinFlexBot'>
            <div className={`${notifi ? 'setNotifi' : ''}`}>{notifi ? notifi : ''}</div>
            <div className='flexBtns'>
                <button className="btn-edit" onClick={() => setLang('EN')}>EN</button>
                <button className="btn-red" onClick={() => setLang('RU')}>RU</button>
            </div>
            <button style={{ marginBottom: '50px' }} className="btn-confirm" onClick={handleSave}>Сохранить изменения</button>
            <div style={{ marginBottom: '10px' }} className='user_str'>
                <div className='addictUserStr' style={{ minWidth: "270px" }}>Команда</div>
                <div className='addictUserStr' style={{ minWidth: "550px" }}>Текстовый перевод</div>
            </div>

            <div style={{ marginBottom: '15px' }}>
                {Object.keys(messages[lang] || {}).map((key) => (
                    <div className='user_str' key={key}>
                        <div style={{ minWidth: "270px" }}>{key}</div>
                        {
                            (key === "HelloMessage")
                                ?
                                <textarea
                                    type="text"
                                    style={{fontFamily: "Montserrat"}}
                                    className='input_field_sec_sec ups-ttc'
                                    value={messages[lang][key]}
                                    onChange={(e) => handleInputChange(e, key)}
                                />
                                :
                                <input
                                    type="text"
                                    className='input_field_sec_sec'
                                    value={messages[lang][key]}
                                    onChange={(e) => handleInputChange(e, key)}
                                />
                        }
                    </div>
                ))}
            </div>
        </div>
    );
}

export default EditMessages;
