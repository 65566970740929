import React, { useState } from 'react';

const AccordionItem = ({ title, children }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div>
      <div onClick={toggleAccordion} style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: "start",
        cursor: 'pointer',
        fontWeight: 'bold',
        padding: '10px',
        background: '#f0f0f0'
      }}>
        <svg
          style={{ transform: isOpen ? 'rotate(180deg)' : 'rotate(90deg)', marginRight: '8px' }}
          xmlns="http://www.w3.org/2000/svg"
          fill="#015ca7"
          width="15px"
          height="15px"
          viewBox="0 0 512 512">
          <polygon points="256 32 20 464 492 464 256 32" />
        </svg>
        {title}
      </div>
      {isOpen && <div style={{ padding: '10px', background: '#fafafa' }}>{children}</div>}
    </div>
  );
};

const Accordion = ({ children }) => {
  return (
    <div>
      {children}
    </div>
  );
};

export default AccordionItem;
export { Accordion };
