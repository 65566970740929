import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import ImageUploader from './ImageUploader';
import { SpinnerCircular } from 'spinners-react';

const CreateChildModal = ({ isOpen, onRequestClose, onSubmit, parentData }) => {
  const [childType, setChildType] = useState('Фаза');
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    child_title: '',
    description: '',
    emirate: '',
    location: '',
    handover_date: '',
    date_of_sell_start: '',
    start_price: '',
  });

  const resetFormData = () => {
    setFormData({
      child_title: '',
      description: '',
      emirate: '',
      location: '',
      handover_date: '',
      date_of_sell_start: '',
      start_price: '',
    });
  };

  useEffect(() => {
    if (parentData) {
      setChildType(parentData.type === 'Проект' ? 'Фаза' : 'Апартаменты');
    }
  }, [parentData]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const validateForm = () => {
    for (let key in formData) {
      if (/[Ъ]/.test(formData[key])) {
        return `Поле "${key}" содержит недопустимые символы`;
      }
    }
    return '';
  };

  const [mainFiles, setMainFiles] = useState([]);
  const [secondFiles, setSecondFiles] = useState([]);
  const [thirdFiles, setThirdFiles] = useState([]);

  const handleMainFilesUpload = (files) => setMainFiles(files);
  const handleSecondFilesUpload = (files) => setSecondFiles(files);
  const handleThirdFilesUpload = (files) => setThirdFiles(files);

  const sendFiles = async (files, id, route) => {
    const imgUrl = `/api/${childType === 'Фаза' ? 'packs' : 'apartments'}/${id}/${route}`;
    const formData = new FormData();
    files.forEach((file) => {
      formData.append('files', file);
    });
    try {
      const response = await fetch(imgUrl, {
        method: 'PUT',
        headers: { 'accept': 'application/json' },
        body: formData,
      });
      if (!response.ok) {
        console.error(`Ошибка загрузки файлов на ${route}`);
      }
    } catch (error) {
      console.error(`Ошибка сети на ${route}:`, error);
    }
  };

  const [FORMLASTDATA, setFORMLASTDATA] = useState(NaN)
  const [formError, setFormError] = useState('');
  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    let endpoint = '';
    let bodyData = {};

    const errorMessage = validateForm();
    if (errorMessage) {
      setFormError(errorMessage);
      setIsLoading(false);
      return;
    }

    if (childType === 'Фаза') {
      endpoint = '/api/packs';
      bodyData = {
        pack_title: formData.child_title,
        project_id: parentData.project_id,
        description: formData.description,
        price: formData.emirate,
        unit_config: formData.location,
        date_of_ending: formData.handover_date,
        date_of_sell_start: formData.date_of_sell_start,
        item_1: formData.installment,
        item_2: formData.start_price,
      };
    } else if (childType === 'Апартаменты') {
      endpoint = '/api/apartments';
      bodyData = {
        apartments_title: formData.child_title,
        project_id: parentData.pack_id ? null : parentData.project_id,
        pack_id: parentData.pack_id ? parentData.pack_id : null,
        description: formData.description,
        price: formData.emirate,
        location: formData.location,
        date_of_sell_start: formData.date_of_sell_start,
        start_price: formData.start_price,
        type: 'Апартаменты',
        pack_status: false,
      };
    }

    try {
      const response = await fetch(endpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(bodyData),
      });

      if (response.ok) {
        const result = await response.json();
        setFORMLASTDATA(formData);
        

        if (result.apartments_id || result.pack_id) {
          const id = result.apartments_id || result.pack_id;
          if (mainFiles.length > 0) {
            await sendFiles(mainFiles, id, 'main_files');
          }
          if (secondFiles.length > 0) {
            await sendFiles(secondFiles, id, 'second_files');
          }
          if (thirdFiles.length > 0) {
            await sendFiles(thirdFiles, id, 'third_files');
          }
        }

      } else {
        console.error('Ошибка создания элемента');
      }
    } catch (error) {
      console.error('Ошибка сети:', error);
    } finally {
      setIsLoading(false);
      // onRequestClose();
      onSubmit(FORMLASTDATA);
      resetFormData();
    }
  };


  const renderPhaseInputs = () => (
    <>
      <div className='input_field_alt'>
        <label style={{ fontSize: '15px', fontWeight: '600', color: "#1081df" }}>Название фазы</label>
        <input
          className='input_field'
          value={formData.child_title}
          onChange={handleChange}
          type="text"
          id="child_title"
          name="child_title"
          required
        />
      </div>
      <div className='input_field_alt'>
        <label style={{ fontSize: '15px', fontWeight: '600', color: "#1081df" }}>Описание</label>
        <input
          className='input_field'
          value={formData.description}
          onChange={handleChange}
          type="text"
          id="description"
          name="description"
        />
      </div>
      <div className='input_field_alt'>
        <label style={{ fontSize: '15px', fontWeight: '600', color: "#1081df" }}>Эмират</label>
        <input
          className='input_field'
          value={formData.emirate}
          onChange={handleChange}
          type="text"
          id="emirate"
          name="emirate"
        />
      </div>
      <div className='input_field_alt'>
        <label style={{ fontSize: '15px', fontWeight: '600', color: "#1081df" }}>Локация</label>
        <input
          className='input_field'
          value={formData.location}
          onChange={handleChange}
          type="text"
          id="location"
          name="location"
        />
      </div>
      <div className='input_field_alt'>
        <label style={{ fontSize: '15px', fontWeight: '600', color: "#1081df" }}>Дата сдачи</label>
        <input
          className='input_field'
          value={formData.handover_date}
          onChange={handleChange}
          type="text"
          id="handover_date"
          name="handover_date"
        />
      </div>
      <div className='input_field_alt'>
        <label style={{ fontSize: '15px', fontWeight: '600', color: "#1081df" }}>Рассрочка</label>
        <input
          className='input_field'
          value={formData.date_of_sell_start}
          onChange={handleChange}
          type="text"
          id="date_of_sell_start"
          name="date_of_sell_start"
        />
      </div>
      <div className='input_field_alt'>
        <label style={{ fontSize: '15px', fontWeight: '600', color: "#1081df" }}>Стартовые цены</label>
        <input
          className='input_field'
          value={formData.start_price}
          onChange={handleChange}
          type="text"
          id="start_price"
          name="start_price"
        />
      </div>
      <ImageUploader onImagesUpload={handleMainFilesUpload} label="Брошюры" />
      <ImageUploader onImagesUpload={handleSecondFilesUpload} label="Планировки" />
      <ImageUploader onImagesUpload={handleThirdFilesUpload} label="Картинки" />
    </>
  );

  const renderApartmentInputs = () => (
    <>
      <div className='input_field_alt'>
        <label style={{ fontSize: '15px', fontWeight: '600', color: "#1081df" }}>Название фазы</label>
        <input
          className='input_field'
          value={formData.child_title}
          onChange={handleChange}
          type="text"
          id="child_title"
          name="child_title"
          required
        />
      </div>
      <div className='input_field_alt'>
        <label style={{ fontSize: '15px', fontWeight: '600', color: "#1081df" }}>Описание</label>
        <input
          className='input_field'
          value={formData.description}
          onChange={handleChange}
          type="text"
          id="description"
          name="description"
        />
      </div>
      <div className='input_field_alt'>
        <label style={{ fontSize: '15px', fontWeight: '600', color: "#1081df" }}>Эмират</label>
        <input
          className='input_field'
          value={formData.emirate}
          onChange={handleChange}
          type="text"
          id="emirate"
          name="emirate"
        />
      </div>
      <div className='input_field_alt'>
        <label style={{ fontSize: '15px', fontWeight: '600', color: "#1081df" }}>Локация</label>
        <input
          className='input_field'
          value={formData.location}
          onChange={handleChange}
          type="text"
          id="location"
          name="location"
        />
      </div>
      <div className='input_field_alt'>
        <label style={{ fontSize: '15px', fontWeight: '600', color: "#1081df" }}>Рассрочка</label>
        <input
          className='input_field'
          value={formData.date_of_sell_start}
          onChange={handleChange}
          type="text"
          id="date_of_sell_start"
          name="date_of_sell_start"
        />
      </div>
      <div className='input_field_alt'>
        <label style={{ fontSize: '15px', fontWeight: '600', color: "#1081df" }}>Стартовые цены</label>
        <input
          className='input_field'
          value={formData.start_price}
          onChange={handleChange}
          type="text"
          id="start_price"
          name="start_price"
        />
      </div>
      <ImageUploader onImagesUpload={handleMainFilesUpload} label="Загрузить основные файлы" />
      <ImageUploader onImagesUpload={handleSecondFilesUpload} label="Загрузить вторичные файлы" />
      <ImageUploader onImagesUpload={handleThirdFilesUpload} label="Загрузить третичные файлы" />
    </>
  );

  if (!parentData) {
    return null;
  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      className="modalWindow"
      overlayClassName='modalOverlay'>
      <div style={{ textAlign: 'center' }}>
        Создать
        {isLoading && <SpinnerCircular size={50} color="#1081df" />}
        {!isLoading && parentData.type === 'Проект' && (
          <div>
            <button
              className={`select-btn select-btn-left ${(childType === 'Фаза') ? 'select-btn-active' : ''}`}
              onClick={() => setChildType('Фаза')}>Фаза</button>
            <button
              className={`select-btn select-btn-right ${(childType === 'Апартаменты') ? 'select-btn-active' : ''}`}
              onClick={() => setChildType('Апартаменты')}>Апартаменты</button>
          </div>
        )}
      </div>
      {formError && <p className="error-message">{formError}</p>}
      {!isLoading && (
        <form className='formData' onSubmit={handleSubmit}>
          {childType === 'Фаза' ? renderPhaseInputs() : renderApartmentInputs()}
          <button className='btn-confirm' style={{ marginTop: '10px' }} type="submit">Создать</button>
        </form>
      )}
    </Modal>
  );
};

export default CreateChildModal;
