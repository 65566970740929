import React, { useEffect, useState } from 'react';

function Requests() {
    const [wordCounts, setWordCounts] = useState({});

    useEffect(() => {
        const fetchData = async () => {
            const response = await fetch('/api/reports_words');
            const data = await response.json();

            const aggregatedWords = data.reduce((acc, report) => {
                Object.entries(report.words).forEach(([word, count]) => {
                    acc[word] = (acc[word] || 0) + count;
                });
                return acc;
            }, {});

            setWordCounts(aggregatedWords);
        };

        fetchData();
    }, []);

    const sortedWords = Object.entries(wordCounts).sort((a, b) => b[1] - a[1]);

    return (
        <div>

            <div className='centerial'>
                <div className='fintab '>
                    <div className='finderTab-height fintab-header finderTab finderTab-bold'>Ключевое слово</div>
                    <div className='finderTab-height fintab-header finderTab finderTab-bold'>Кол-во поисков</div>
                </div>
                {sortedWords.map(([word, count]) => (
                    <div className='fintab' key={word}>
                        <div className='finderTab-height finderTab basicfintab' >{word}</div>
                        <div className='finderTab-height finderTab basicfintab'  >{count}</div>
                    </div>
                ))}
                {/* <div className='fintab borderBot'>
                    {sortedWords.map(([word]) => (
                        <div className='finderTab finderTab-bold' key={word}>{word}</div>
                    ))}
                    {sortedWords.map(([_, count]) => (
                        <div className='finderTab' key={count}>{count}</div>
                    ))}
                </div> */}
            </div>
        </div>
    );
}

export default Requests;
