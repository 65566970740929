import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../utils/AuthProvider';

const Login = () => {
    const { login } = useAuth();
    const navigate = useNavigate();
    const [err, setErr] = useState('')

    useEffect(() => {

        const checkAdmin = (username) => {
            return fetch(`/api/check_admin/${username}`)
                .then(response => response.json())
                .then(data => {
                    if (data.detail) {
                        return false
                    } else {
                        if (data) {
                            return true
                        }
                    }

                })
                .catch(error => {
                    return false;
                });
        }

        const handleAuth = (response) => {
            checkAdmin(response.username)
                .then(isAdmin => {
                    if (response && isAdmin) {
                        localStorage.setItem('userPic', response.photo_url);
                        login({ ...response, isAuthenticated: true });
                        navigate('/home');
                    } else {
                        setErr('К сожалению вы не админ, запросите доступ к панели');
                    }
                });
        };

        window.handleTelegramAuth = handleAuth;


        const script = document.createElement('script');
        script.src = "https://telegram.org/js/telegram-widget.js?22";
        script.async = true;
        script.setAttribute("data-telegram-login", "RE_Agent_tool_bot");
        script.setAttribute("data-size", "large");
        script.setAttribute("data-request-access", "write");
        script.setAttribute("data-userpic", "true");
        script.setAttribute("data-onauth", "handleTelegramAuth(user)");
        document.getElementById('telegram-login-button').appendChild(script);
    }, [login, navigate]);

    return (
        <div className='bg-login'>
            <label
                style={{
                    marginBottom: '15px 10px',
                    fontFamily: 'Montserrat',
                    fontSize: '15px',
                    userSelect: 'none'
                }}>ADMIN PANEL</label>
            <label
                style={{
                    fontFamily: 'Montserrat',
                    fontSize: '25px',
                    fontWeight: '700',
                    padding: '5px 20px',
                    border: '2px solid black',
                    borderRadius: '10px',
                    marginBottom: '30px',
                    userSelect: 'none'
                }}
            >re-agent-tool</label>
            <div id="telegram-login-button"></div>
            {err ? <div>{err}</div> : ''}
        </div>
    );
};

export default Login;
