
import React, { useState, useEffect, useMemo, useCallback, useRef } from 'react';
import { Table, Column, HeaderCell, Cell } from 'rsuite-table';
import 'rsuite-table/dist/css/rsuite-table.css';
import EditModalWindow from '../components/EditModalWindow';
import DeleteConfirmationModal from '../components/DeleteConfirmationModal';
import CreateModal from '../components/CreateModal';
import CreateChildModal from '../components/CreateChild';
import { SpinnerCircular } from 'spinners-react';

const CONSTAPIURL = 'https://reagent-tool.webtm.ru';

const getData = (packs, apartments) => {
  const packNodes = packs.map(pack => ({
    ...pack,
    id: pack.pack_id,
    rowKey: `pack-${pack.pack_id}`,
    type: 'Фаза',
    isRoot: true,
    level: 0,
    title: pack.pack_title,
    additional: pack.description,
    children: pack.apartments.map(apartment => ({
      ...apartment,
      id: apartment.apartments_id,
      rowKey: `apartment-${apartment.apartments_id}`,
      parentId: `pack-${pack.pack_id}`,
      type: 'Апартаменты',
      isRoot: false,
      level: 1,
      title: apartment.apartments_title,
      additional: apartment.description
    }))
  }));

  const apartmentNodes = apartments.map(apartment => ({
    ...apartment,
    id: apartment.apartments_id,
    rowKey: `apartment-${apartment.apartments_id}`,
    type: 'Апартаменты',
    isRoot: true,
    level: 0,
    title: apartment.apartments_title,
    additional: apartment.description
  }));

  return [...packNodes, ...apartmentNodes].map(node => {
    if (node.children) {
      node.children = node.children.sort((a, b) => a.title.localeCompare(b.title));
    }
    return node;
  }).sort((a, b) => a.title.localeCompare(b.title));
};

const TreeTable = () => {
  const [isLoading, setIsLoading] = useState(true)
  const [data, setData] = useState([]);
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [createModalIsOpen, setCreateModalIsOpen] = useState(false);
  const [createChildModalIsOpen, setCreateChildModalIsOpen] = useState(false);
  const [selectedRowDataForChild, setSelectedRowDataForChild] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const tableRef = useRef(null);

  const getAllProjects = useCallback(async () => {
    try {
      setIsLoading(true)
      const response = await fetch(CONSTAPIURL + `/api/project/1`);
      const result = await response.json();
      if (result && result.detail !== "Not Found") {
        const { packs, apartments } = result;
        const newData = getData(packs, apartments);
        if (JSON.stringify(newData) !== JSON.stringify(data)) {
          setData(newData);
        }
      }
    }
    catch {
      setIsLoading(false)
    }
    finally {
      setIsLoading(false)
    }

  }, [data])

  const handleCreateChild = (formData) => {
    setCreateChildModalIsOpen(false);
    getAllProjects();
  };

  useEffect(() => {
    getAllProjects();
  }, [getAllProjects]);


  const handleConfirmDelete = useCallback(async () => {
    let deleteRoute = '';
    if (selectedRowData.pack_title) {
      deleteRoute = CONSTAPIURL + `/api/packs/${selectedRowData.pack_id}`;
    } else if (selectedRowData.apartments_title) {
      deleteRoute = CONSTAPIURL + `/api/apartments/${selectedRowData.apartments_id}`;
    }
    try {
      const response = await fetch(deleteRoute, { method: 'DELETE' });
      if (response.ok) {
        console.log('Удаление успешно');
        getAllProjects();
      } else {
        console.error('Ошибка удаления');
      }
    } catch (error) {
      console.error('Ошибка сети:', error);
    }
    setIsModalOpen(false);
  }, [selectedRowData, getAllProjects]);

  const handleEditClick = useCallback((rowData) => {
    setSelectedRowData(rowData);
    setIsEditModalOpen(true);
  }, []);

  const handleDeleteClick = useCallback((rowData) => {
    setSelectedRowData(rowData);
    setIsModalOpen(true);
  }, []);

  const handleEditConfirmed = useCallback(() => {
    setIsEditModalOpen(false);
    getAllProjects();
  }, [getAllProjects]);

  const handleExpandChange = useCallback((expanded, rowData) => {
    setExpandedRowKeys(prevKeys =>
      expanded ? [...prevKeys, rowData.rowKey] : prevKeys.filter(key => key !== rowData.rowKey)
    );
  }, []);

  const renderCell = useCallback((rowData, dataKey) => {
    if (dataKey === 'title') {
      return <span>{rowData.title}</span>;
    }
    return rowData[dataKey];
  }, []);

  const getTypeBlock = useCallback((rowData, type) => {
    let classType = '';
    let newType = '';
    var isChildren = false;
    if (rowData.children && rowData.children.length > 0) {
      isChildren = true;
    }

    if (type === 'Фаза') {
      classType = 'tc-phase';
      newType = 'Проект';
    } else if (type === 'Апартаменты') {
      classType = 'tc-aps';
      newType = 'Фаза';
    }

    return <div className={`type-cell ${classType} ${isChildren ? 'childTrue' : ''}`}>{newType}</div>;
  }, []);

  const renderButtonCell = useCallback((rowData) => (
    <div className='btns-row'>
      {rowData.type !== 'Апартаменты' && (
        <button className='btn-confirm' style={{ width: '34px', fontSize: '18px', fontWeight: '500', borderRadius: '15px', marginRight: '5px' }} onClick={() => {
          setSelectedRowDataForChild(rowData);
          setCreateChildModalIsOpen(true);
        }}>+</button>
      )}
      <button className='btn-edit' onClick={() => handleEditClick(rowData)}>Изменить</button>
      <div className='delete-btn' onClick={() => handleDeleteClick(rowData)}>
        <svg className='trash-btn' viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M4 6H20M16 6L15.7294 5.18807C15.4671 4.40125 15.3359 4.00784 15.0927 3.71698C14.8779 3.46013 14.6021 3.26132 14.2905 3.13878C13.9376 3 13.523 3 12.6936 3H11.3064C10.477 3 10.0624 3 9.70951 3.13878C9.39792 3.26132 9.12208 3.46013 8.90729 3.71698C8.66405 4.00784 8.53292 4.40125 8.27064 5.18807L8 6M18 6V16.2C18 17.8802 18 18.7202 17.673 19.362C17.3854 19.9265 16.9265 20.3854 16.362 20.673C15.7202 21 14.8802 21 13.2 21H10.8C9.11984 21 8.27976 21 7.63803 20.673C7.07354 20.3854 6.6146 19.9265 6.32698 19.362C6 18.7202 6 17.8802 6 16.2V6M14 10V17M10 10V17" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
      </div>
    </div>
  ), [handleDeleteClick, handleEditClick]);

  const filteredData = useMemo(() => {
    return data.filter(item => {
      return Object.values(item).some(val =>
        String(val).toLowerCase().includes(searchTerm.toLowerCase())
      ) || (item.children && item.children.some(child =>
        Object.values(child).some(val =>
          String(val).toLowerCase().includes(searchTerm.toLowerCase())
        )
      ));
    });
  }, [data, searchTerm]);

  return (
    <>
      <div style={{
        marginTop: '25px',
        marginBottom: '15px',
        marginRight: '15px',
        width: '100%',
        display: 'flex',
        justifyContent: 'end',
        fontSize: '24px',
        overflowY: "auto"
      }}>
        <button className='btn-confirm' style={{ marginLeft: 'auto' }} onClick={() => setCreateModalIsOpen(true)}>Создать проект</button>
      </div>

      <div style={{ marginBottom: '20px', display: 'flex', justifyContent: 'center' }}>
        <input
          type="text"
          placeholder="Поиск..."
          value={searchTerm}
          onChange={e => setSearchTerm(e.target.value)}
          style={{ width: '300px', padding: '10px', fontSize: '16px' }}
        />
      </div>

      <CreateModal isOpen={createModalIsOpen} onRequestClose={() => setCreateModalIsOpen(false)} onSubmit={(formData) => {
        setCreateModalIsOpen(false);
        getAllProjects();
      }} />
      <CreateChildModal isOpen={createChildModalIsOpen} onRequestClose={() => { setCreateChildModalIsOpen(false); getAllProjects(); }} onSubmit={handleCreateChild} parentData={selectedRowDataForChild} />
      <DeleteConfirmationModal isOpen={isModalOpen} onRequestClose={() => setIsModalOpen(false)} onConfirm={handleConfirmDelete} selectedRowData={selectedRowData} />
      <EditModalWindow isOpen={isEditModalOpen} onRequestClose={() => setIsEditModalOpen(false)} onConfirm={handleEditConfirmed} selectedRowData={selectedRowData} getAllProjects={getAllProjects} />
      {!isLoading ? <Table
        height={600}
        width={1000}
        data={filteredData}
        isTree
        expandedRowKeys={expandedRowKeys}
        onExpandChange={handleExpandChange}
        rowKey="rowKey"
        virtualized
      >
        <Column width={100}>
          <HeaderCell style={{ textAlign: 'center', fontWeight: 'bold' }}>ID</HeaderCell>
          <Cell style={{ textOverflow: "visible" }} dataKey="id" />
        </Column>
        <Column width={200}>
          <HeaderCell style={{ textAlign: 'center', fontWeight: 'bold' }}>Тип</HeaderCell>
          <Cell>{rowData => <div style={{ display: 'flex', alignItems: 'end', justifyContent: 'end' }}>{getTypeBlock(rowData, rowData.type)}</div>}</Cell>
        </Column>
        <Column width={450}>
          <HeaderCell style={{ textAlign: 'center', fontWeight: 'bold' }}>Название</HeaderCell>
          <Cell>{rowData => renderCell(rowData, 'title')}</Cell>
        </Column>
        <Column width={200}>
          <HeaderCell style={{ textAlign: 'center', fontWeight: 'bold' }}>Действие</HeaderCell>
          <Cell className='btnCell'>{rowData => renderButtonCell(rowData)}</Cell>
        </Column>
      </Table> : <SpinnerCircular className='spinner' color="#1081df" secondaryColor='#e2e2e2' size="100" />}
    </>
  );
};

export default TreeTable;
