import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { AuthProvider, useAuth } from './utils/AuthProvider';
import Login from './routes/Login';
import Workspace from './routes/Workspace';
import './App.css';
import Modal from 'react-modal';
import EditNames from './components/EditNames';
import EditMessages from './components/EditMessages';
import TreeTable from './components/table';
import Requests from './components/Requests';

const ProtectedRoute = ({ element: Component, ...rest }) => {
    const { user } = useAuth();
    // return <Component {...rest} />
    return user ? <Component {...rest} /> : <Navigate to="/login" />;
};

const InitialRedirect = () => {
    const { user } = useAuth();
    return user ? <Navigate to="/projects" /> : <Navigate to="/login" />;
};

const App = () => {
    return (
        <AuthProvider>
            <Router>
                <Routes>
                    <Route path="/login" element={<Login />} />
                    <Route path="/" element={<InitialRedirect />} />
                    <Route path="/" element={<ProtectedRoute element={Workspace} />}>
                        <Route path="projects" element={<TreeTable />} />
                        <Route path="users" element={<EditNames />} />
                        <Route path="lang" element={<EditMessages />} />
                        <Route path="requests" element={<Requests />} />
                        <Route path="*" element={<Navigate to="projects" />} />
                    </Route>
                    <Route path="*" element={<Navigate to="/projects" />} />
                </Routes>
            </Router>
        </AuthProvider>
    );
};

export default App;
