import React, { useState } from 'react';
import Modal from 'react-modal';
import ImageUploader from './ImageUploader';
import { SpinnerCircular } from 'spinners-react';

const CreateModal = ({ isOpen, onRequestClose, onSubmit }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [formData, setFormData] = useState({
    pack_title: '',
    project_id: 1,
    description: '',
    price: '',
    unit_config: '',
    date_of_ending: '',
    date_of_sell_start: '',
    start_price: '',
    item_1: ''
  });

  const [mainFiles, setMainFiles] = useState([]);
  const [secondFiles, setSecondFiles] = useState([]);
  const [thirdFiles, setThirdFiles] = useState([]);

  const handleMainFilesUpload = (files) => setMainFiles(files);
  const handleSecondFilesUpload = (files) => setSecondFiles(files);
  const handleThirdFilesUpload = (files) => setThirdFiles(files);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const resetFormData = () => {
    setFormData({
      pack_title: '',
      project_id: 1,
      description: '',
      price: '',
      unit_config: '',
      date_of_ending: '',
      date_of_sell_start: '',
      start_price: '',
      item_1: ''
    });
    setMainFiles([]);
    setSecondFiles([]);
    setThirdFiles([]);
  };

  const sendFiles = (files, pack_id, route) => {
    const imgUrl = `/api/pack/${pack_id}/${route}`;
    const formData = new FormData();
    files.forEach((file) => {
      formData.append('files', file);
    });
    return fetch(imgUrl, {
      method: 'PUT',
      headers: {
        'accept': 'application/json',
      },
      body: formData,
    }).then(response => {
      if (!response.ok) {
        throw new Error(`Ошибка загрузки файлов на ${route}`);
      }
    });
  };

  const [FINDATA, setFINDATA] = useState(NaN)

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    const bodyData = { ...formData, item_2: formData.start_price };
    try {
      const response = await fetch('/api/packs', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(bodyData),
      });
      if (response.ok) {
        const result = await response.json();
        setFINDATA(formData)
        
        const fileUploads = [
          mainFiles.length > 0 && sendFiles(mainFiles, result.pack_id, 'main_files'),
          secondFiles.length > 0 && sendFiles(secondFiles, result.pack_id, 'second_files'),
          thirdFiles.length > 0 && sendFiles(thirdFiles, result.pack_id, 'third_files'),
        ].filter(Boolean);
        await Promise.all(fileUploads);
        resetFormData();
      } else {
        console.error('Ошибка создания проекта');
      }
    } catch (error) {
      console.error('Ошибка сети:', error);
    } finally {
      setIsLoading(false);
      onSubmit(FINDATA)
      // onRequestClose();
    }
  };
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      className="modalWindow"
      overlayClassName='modalOverlay'>

      <div style={{ textAlign: 'center' }}>Создать проект</div>
      {
        isLoading ? (
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
            <SpinnerCircular />
          </div>
        ) : (
          <form className='formData' onSubmit={handleSubmit}>
            <div>
              <label>Название проекта</label>
              <input className='input_field' value={formData.pack_title} onChange={handleChange} type="text" id="pack_title" name="pack_title" required />
            </div>
            <div>
              <label>Застройщик</label>
              <input className='input_field' value={formData.description} onChange={handleChange} type="text" id="description" name="description" />
            </div>
            <div>
              <label>Эмират</label>
              <input className='input_field' value={formData.price} onChange={handleChange} type="text" id="price" name="price" />
            </div>
            <div>
              <label>Локация</label>
              <input className='input_field' value={formData.unit_config} onChange={handleChange} type="text" id="unit_config" name="unit_config" />
            </div>
            <div>
              <label>Дата сдачи</label>
              <input className='input_field' value={formData.date_of_ending} onChange={handleChange} type="text" id="date_of_ending" name="date_of_ending" />
            </div>
            <div>
              <label>Рассрочка</label>
              <input className='input_field' value={formData.date_of_sell_start} onChange={handleChange} type="text" id="date_of_sell_start" name="date_of_sell_start" />
            </div>
            <div>
              <label>Стартовые цены</label>
              <input className='input_field' value={formData.start_price} onChange={handleChange} type="text" id="start_price" name="start_price" />
            </div>
            <div>
              <label>Описание</label>
              <input className='input_field' value={formData.item_1} onChange={handleChange} type="text" id="item_1" name="item_1" />
            </div>
            <ImageUploader onImagesUpload={handleMainFilesUpload} label="Загрузить основные файлы" />
            <ImageUploader onImagesUpload={handleSecondFilesUpload} label="Загрузить вторичные файлы" />
            <ImageUploader onImagesUpload={handleThirdFilesUpload} label="Загрузить третичные файлы" />
            <button
              className='btn-confirm'
              style={{ marginTop: '10px' }}
              type="submit">Создать</button>
          </form>
        )
      }
    </Modal >
  );
};

export default CreateModal;

