import React, { useState, useEffect } from 'react';
import PDFpng from '../images/pdf.png';

const ImageUploader = ({ onImagesUpload, label }) => {
    const [images, setImages] = useState([]);
    const [imageFiles, setImageFiles] = useState([]);

    const handleImageUpload = (e) => {
        const files = Array.from(e.target.files);
        const newImages = files.map(file => {
            if (file.type === 'application/pdf') {
                return PDFpng;
            } else {
                return URL.createObjectURL(file);
            }
        });
        setImages([...images, ...newImages]);
        setImageFiles([...imageFiles, ...files]);
    };

    const handleRemoveImage = (index) => {
        setImages(images.filter((_, i) => i !== index));
        setImageFiles(imageFiles.filter((_, i) => i !== index));
    };

    useEffect(() => {
        onImagesUpload(imageFiles);
    }, [imageFiles, onImagesUpload]);

    return (
        <div className='image-uploader'>
            <label style={{
                fontSize: '15px',
                fontWeight: '600',
                color: "#1081df",
                marginBottom: '5px'
            }}>{label}</label>
            <div className='image-previews'>
                {images.map((img, index) => (
                    <div key={index} className='image-preview'>
                        <img src={img} alt={`preview ${index}`} />
                        <button className='remove-button' onClick={() => handleRemoveImage(index)}>✖</button>
                    </div>
                ))}
                <div className='image-upload-button'>
                    <label>
                        <input type='file' multiple onChange={handleImageUpload} />
                        <span>+</span>
                    </label>
                </div>
            </div>
        </div>
    );
};

export default ImageUploader;