import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import ImageUploader from './ImageUploader';
import PDFpng from '../images/pdf.png';
import { SpinnerCircular } from 'spinners-react';

const EditModalWindow = ({ isOpen, onRequestClose, onConfirm, selectedRowData, getAllProjects }) => {
  const [mainData, setMainData] = useState(selectedRowData);
  const [originalData, setOriginalData] = useState(selectedRowData);
  const [isActiveSendBtn, setIsActiveSendBtn] = useState(false);
  const [removeMainFiles, setRemoveMainFiles] = useState([]);
  const [removeSecondFiles, setRemoveSecondFiles] = useState([]);
  const [removeThirdFiles, setRemoveThirdFiles] = useState([]);
  const [newMainFiles, setNewMainFiles] = useState([]);
  const [newSecondFiles, setNewSecondFiles] = useState([]);
  const [newThirdFiles, setNewThirdFiles] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setMainData(selectedRowData);
    setOriginalData(selectedRowData);
    setRemoveMainFiles([]);
    setRemoveSecondFiles([]);
    setRemoveThirdFiles([]);
  }, [selectedRowData]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    const updatedData = {
      ...mainData,
      [name]: value,
    };
    setMainData(updatedData);
    const isDataChanged = Object.keys(originalData).some(
      (key) => originalData[key] !== updatedData[key]
    );
    setIsActiveSendBtn(isDataChanged || removeMainFiles.length > 0 || removeSecondFiles.length > 0 || removeThirdFiles.length > 0 || newMainFiles.length > 0 || newSecondFiles.length > 0 || newThirdFiles.length > 0);
  };

  const getLabelInfo = (field) => {
    if (mainData.type === 'Апартаменты') {
      switch (field) {
        case 'apartments_title': return 'Название апартаментов';
        case 'description': return 'Описание';
        case 'price': return 'Эмират';
        case 'location': return 'Локация';
        case 'date_of_sell_start': return 'Рассрочка';
        case 'start_price': return 'Стартовые цены';
        default: return '';
      }
    } else {
      switch (field) {
        case 'pack_title': return 'Название';
        case 'description': return 'Застройщик';
        case 'price': return 'Эмират';
        case 'unit_config': return 'Локация';
        case 'date_of_ending': return 'Дата сдачи';
        case 'date_of_sell_start': return 'Рассрочка';
        case 'start_price': return 'Стартовые цены';
        case 'item_2': return 'Стартовые цены';
        case 'item_1': return 'Описание';
        default: return '';
      }
    }
  };

  const handleFileRemove = (event, file, type) => {
    event.preventDefault();
    event.stopPropagation();
    switch (type) {
      case 'main':
        setRemoveMainFiles(prevFiles => [...prevFiles, file]);
        setMainData({
          ...mainData,
          main_files: mainData.main_files.filter(image => image !== file)
        });
        break;
      case 'second':
        setRemoveSecondFiles(prevFiles => [...prevFiles, file]);
        setMainData({
          ...mainData,
          second_files: mainData.second_files.filter(image => image !== file)
        });
        break;
      case 'third':
        setRemoveThirdFiles(prevFiles => [...prevFiles, file]);
        setMainData({
          ...mainData,
          third_files: mainData.third_files.filter(image => image !== file)
        });
        break;
      default:
        break;
    }
    setIsActiveSendBtn(true);
  };

  const handleNewFiles = (files, type) => {
    switch (type) {
      case 'main':
        setNewMainFiles(files);
        break;
      case 'second':
        setNewSecondFiles(files);
        break;
      case 'third':
        setNewThirdFiles(files);
        break;
      default:
        break;
    }
    setIsActiveSendBtn(true);
  };

  const sendFiles = async (files, id, type) => {
    setIsLoading(true);
    const imgUrl = `/api/${mainData.type === 'Апартаменты' ? 'apartments' : 'pack'}/${id}/${type}`;
    const formData = new FormData();
    files.forEach((file) => {
      formData.append('files', file);
    });
    try {
      const response = await fetch(imgUrl, {
        method: 'PUT',
        body: formData,
      });
      if (!response.ok) {
        throw new Error(`Ошибка загрузки файлов на ${type}`);
      }
    } catch (error) {
      console.error(`Ошибка сети на ${type}:`, error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    const data = mainData.type === 'Апартаменты'
      ? {
        apartments_title: mainData.apartments_title,
        project_id: mainData.project_id,
        description: mainData.description,
        price: mainData.price,
        location: mainData.location,
        date_of_sell_start: mainData.date_of_sell_start,
        start_price: mainData.start_price,
        item_1: mainData.item_1,
        main_files: mainData.main_files,
        second_files: mainData.second_files,
        third_files: mainData.third_files,
        remove_main_files: removeMainFiles,
        remove_second_files: removeSecondFiles,
        remove_third_files: removeThirdFiles,
      }
      : {
        pack_title: mainData.pack_title,
        project_id: mainData.project_id,
        description: mainData.description,
        price: mainData.price,
        unit_config: mainData.unit_config,
        date_of_ending: mainData.date_of_ending,
        date_of_sell_start: mainData.date_of_sell_start,
        start_price: mainData.start_price,
        item_1: mainData.item_1,
        item_2: mainData.item_2,
        remove_main_files: removeMainFiles,
        remove_second_files: removeSecondFiles,
        remove_third_files: removeThirdFiles,
      };

    const response = await fetch(`/api/${mainData.type === 'Апартаменты' ? 'apartments' : 'packs'}/${mainData.id}`, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data),
    });

    if (response.ok) {
      if (newMainFiles.length > 0) {
        await sendFiles(newMainFiles, mainData.id, 'main_files');
      }
      if (newSecondFiles.length > 0) {
        await sendFiles(newSecondFiles, mainData.id, 'second_files');
      }
      if (newThirdFiles.length > 0) {
        await sendFiles(newThirdFiles, mainData.id, 'third_files');
      }
      onConfirm(mainData);
      getAllProjects();
    }
    setIsLoading(false);
  };

  const renderImagePreview = (img, index, type) => {
    const isPdf = img.endsWith('.pdf');
    const displayName = isPdf ? `${img.slice(0, 3)}...${img.slice(-6)}` : null;

    return (
      <div key={index} className='image-preview'>
        {isPdf ? (
          <>
            <a href={img} target="_blank">
              <img src={PDFpng} alt={`preview ${index}`} />
              <p>{displayName}</p>
            </a>
          </>
        ) : (
          <img src={img} alt={`preview ${index}`} />
        )}
        <button className='remove-button' onClick={(e) => handleFileRemove(e, img, type)}>✖</button>
      </div>
    );
  };

  const renderFields = (mainData) => {
    if (!mainData) return null;
    const fields = mainData.type === 'Апартаменты'
      ? ['apartments_title', 'description', 'price', 'location', 'date_of_sell_start', 'start_price']
      : ['pack_title', 'description', 'price', 'unit_config', 'date_of_ending', 'date_of_sell_start', 'item_2', 'item_1'];

    mainData.main_files = mainData.main_files || [];
    mainData.second_files = mainData.second_files || [];
    mainData.third_files = mainData.third_files || [];

    return (
      <form className='formData'>
        {fields.map((field, index) => (
          <div key={index}>
            <label style={{
              fontSize: '15px',
              fontWeight: '600',
              color: "#1081df",
              margin: '0 auto',
            }}>{getLabelInfo(field)}</label>
            <div className='input-edit' key={field}>
              <input
                className='input_field_sec'
                value={mainData[field] || ''}
                onChange={handleChange}
                type="text"
                id={field}
                name={field}
                required
              />
            </div>
          </div>
        ))}
        <div>
          <label style={{
            fontSize: '15px',
            fontWeight: '600',
            color: "#1081df",
            margin: '0 auto',
          }}>Брошюры</label>
          <div className='image-previews'>
            {mainData.main_files.length > 0 ? (
              mainData.main_files.map((img, index) => renderImagePreview(img, index, 'main'))
            ) : (
              <p>Нет брошюр</p>
            )}
          </div>
          <ImageUploader onImagesUpload={(files) => handleNewFiles(files, 'main')} />
        </div>
        <div>
          <label style={{
            fontSize: '15px',
            fontWeight: '600',
            color: "#1081df",
            margin: '0 auto',
          }}>Планировки</label>
          <div className='image-previews'>
            {mainData.second_files.length > 0 ? (
              mainData.second_files.map((img, index) => renderImagePreview(img, index, 'second'))
            ) : (
              <p>Нет планировок</p>
            )}
          </div>
          <ImageUploader onImagesUpload={(files) => handleNewFiles(files, 'second')} />
        </div>
        <div>
          <label style={{
            fontSize: '15px',
            fontWeight: '600',
            color: "#1081df",
            margin: '0 auto',
          }}>Картинки</label>
          <div className='image-previews'>
            {mainData.third_files.length > 0 ? (
              mainData.third_files.map((img, index) => renderImagePreview(img, index, 'third'))
            ) : (
              <p>Нет картинок</p>
            )}
          </div>
          <ImageUploader onImagesUpload={(files) => handleNewFiles(files, 'third')} />
        </div>
        <button className='btn-confirm' style={{ marginTop: '10px' }} disabled={!isActiveSendBtn} type="button" onClick={handleSubmit}>Сохранить изменения</button>
      </form>
    );
  };

  return (
    <Modal className="modalWindow" overlayClassName="modalOverlay" isOpen={isOpen} onRequestClose={onRequestClose} contentLabel="Редактировать">
      <div style={{ textAlign: 'center' }}>Редактировать</div>
      {isLoading ? (
        <SpinnerCircular size={50} color="#1081df" />
      ) : (
        renderFields(mainData)
      )}
    </Modal>
  );
};

export default EditModalWindow;
