import React from 'react';
import Modal from 'react-modal';
import Accordion from './Accordeon';

const DeleteConfirmationModal = ({ isOpen, onRequestClose, onConfirm, selectedRowData }) => {
  return (
    <Modal className="modalWindow" overlayClassName='modalOverlay' isOpen={isOpen} onRequestClose={onRequestClose} contentLabel="Подтверждение удаления">
      <p style={{ margin: 0, textAlign: 'center' }}>Вы уверены, что хотите удалить этот элемент?</p>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '10px', marginBottom: '10px' }}>
        <button className='btn-confirm big-btn' onClick={onRequestClose}>Нет</button>
        <button className='btn-red big-btn' onClick={onConfirm}>Да</button>
      </div>
      {selectedRowData && ((selectedRowData.packs && selectedRowData.packs.length > 0) || (selectedRowData.apartments && selectedRowData.apartments.length > 0)) && (
        <Accordion title="Будут безвозвратно удалены">
          <div style={{ maxHeight: '200px', overflow: 'auto' }}>
            {selectedRowData.apartments && selectedRowData.apartments.length > 0 && (
              <Accordion title="Апартаменты">
                {selectedRowData.apartments.map((apartment, index) => (
                  <div style={{ marginLeft: '15px' }} key={index}>{apartment.apartments_title}</div>
                ))}
              </Accordion>
            )}
            {selectedRowData.packs && selectedRowData.packs.length > 0 && (
              <Accordion title="Фазы с апартаментами">
                {selectedRowData.packs.map((pack, index) => (
                  <div key={index}>
                    <div>{pack.pack_title}</div>
                    {pack.apartments && pack.apartments.length > 0 && pack.apartments.map((apart, chindex) => (
                      <div style={{ marginLeft: '15px' }} key={chindex}>{apart.apartments_title}</div>
                    ))}
                  </div>
                ))}
              </Accordion>
            )}
          </div>
        </Accordion>
      )}
    </Modal>
  );
};

export default DeleteConfirmationModal;
